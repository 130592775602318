<template>
    <div>
        <div class="main">
            <el-form label-position="right" label-width="111px" :model="qhRecruit" >
                <!-- 招募的案件 -->
                <el-form-item label="募集案件" v-if="kind == 2">
                    <el-select size="small" v-model="caseVal" placeholder="選択下さい">
                        <el-option
                        v-for="item in caseArr"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <!-- 招募时间 -->
                <el-form-item label="募集期間">
                    <el-date-picker
                    size="small"
                    v-model="qhRecruit.startTime"
                    type="date"
                    default-value
                    format="yyyy - MM - dd"
                    value-format="yyyy-MM-dd"
                    :picker-options="pickerOptionsStart"
                    placeholder="日付選択">
                    </el-date-picker>
                    <span style="display:inline-block;width:50px;text-align:center">-</span>
                    <el-date-picker
                    size="small"
                    :picker-options="pickerOptionsEnd"
                    v-model="qhRecruit.endTime"
                    type="date"
                    default-value
                    format="yyyy - MM - dd"
                    value-format="yyyy-MM-dd"
                    placeholder="日付選択">
                    </el-date-picker>
                </el-form-item>
                <!-- 招聘职位 -->
                <el-form-item label="応募ポジション">
                    <div class="post-box">
                        <ul>
                            <li v-for="(item,index) in qhRecruit.data" :key="item.id">
                                <el-checkbox v-model="item.num > 0">{{item.post}}</el-checkbox>
                                <el-input-number size="small" :min="0" v-model="item.num"></el-input-number>
                            </li>
                        </ul>
                    </div>
                </el-form-item>
                <!-- 招聘条件 -->
                <el-form-item label="募集要項">
                    <el-input
                    type="textarea"
                    placeholder="募集要項を入力下さい"
                    v-model="qhRecruit.outline"
                    maxlength="100"
                    show-word-limit
                    :autosize="{ minRows: 3, maxRows: 4}"
                    style="width:80%"
                    >
                    </el-input>
                </el-form-item>
                <!-- 图标 -->
                <el-form-item  label="ヘッダー画像">
                    <el-upload
                    class="avatar-uploader"
                    action="no"
                    accept=".jpg, .jpeg, .png"
                    :http-request="uploadrecFile"
                    :show-file-list="false">
                    <el-avatar
                        shape="square"
                        :size="80"
                        fit="fill"
                        :src="iconName"
                    ></el-avatar>
                        <!-- <img class="imgurl" v-if="iconName" :size="80" :src="iconName">
                        <i v-else class="el-icon-camera avatar-uploader-icon"></i>  -->
                    </el-upload>
                </el-form-item>
               
            </el-form>
            <div>
                <!-- 开始招聘 -->
                <span class="begin" @click="beginRecruit">募集開始</span>
                <!-- 结束招聘 -->
                <!-- <span class="end" @click="endRecruit">募集完了</span> -->
            </div>
        </div>
    </div>
</template>

<script>
import service from '@/service'
    export default {
        name: "recruit",
        props: {
            qhRecruit: {
                tyepe: Object,
                default:() => {
                    return {}
                }
            },
            kind: {
                type: Number,
                default:() => {
                    return 1
                }
            },
        },
        data() {
            return {
                expierTimeOption: {
                    disabledDate(time) {
                        return time.getTime()　<　Date.now()
                    }
                },
                pickerOptionsStart: {
                    disabledDate: time => {
                        let endDateVal = this.qhRecruit.endTime;
                        if (endDateVal) {
                            return time.getTime() > new Date(endDateVal).getTime();
                        } else {
                            return time.getTime() < Date.now() - 8.64e7;
                        }
                    }
                },
                pickerOptionsEnd: {
                    disabledDate: time => {
                        let beginDateVal = this.qhRecruit.startTime;
                        if (beginDateVal) {
                            return (time.getTime() < new Date(beginDateVal).getTime()
                            )
                        } else {
                            return time.getTime() < Date.now() - 8.64e7;
                        }
                    },
                },
                iconName: "",
                caseVal: '' ,
                caseArr: [],
                ispub: 0,
            }   
        },
        mounted() {
            service.personal.getchpTitle({projectid:this.$route.query.pid}).then(res => {
                if(res.status === 200 && res.data.length) {
                    this.caseArr = res.data;
                }
            }) 
        },
        watch: {
        'qhRecruit.startTime'() {
			if (this.qhRecruit.startTime == null) {
				this.qhRecruit.endTime = null
			}
		}
        },
        methods: {
           
            beginRecruit() {
                let selected = [];
                this.qhRecruit.data.forEach(item => {
                    let obj = {};
                    if(item.num) {
                        obj[item.id] = item.num
                        selected.push(obj)
                    }
                });
                if(selected.length) {
                    let isSelected = []
                   selected.forEach(item => {
                        Object.keys(item).map(key => {
                            isSelected.push(item[key])
                        })
                   })
                   let status = isSelected.some(item => item > 0)
                   if(status && this.qhRecruit.startTime && this.qhRecruit.endTime) {
                       let params = {
                            projectid: this.$route.query.pid,
                            stime: this.qhRecruit.startTime,
                            etime: this.qhRecruit.endTime,
                            condition: this.qhRecruit.outline,
                            icon: this.iconName,
                            poses: selected,
                            ispub: this.ispub,
                            kind: this.kind
                        }
                
                        if(this.kind != 1) {
                            params.chapterid = this.caseVal
                        }
                        this.recruitInfo(params)
                   } else {
                       if(!status) {
                            this.$message.error("募集人数を入力下さい")
                            return false
                       }
                      if(!this.qhRecruit.startTime ) {
                          this.$message.error("募集開始時間を選択下さい")
                          return false
                      }
                      if(!this.qhRecruit.endTime ) {
                          this.$message.error("募集終了時間を選択下さい")
                          return false
                      }
                   }
                this.iconName = ""
                }
                
            },
            endRecruit() {

            },
            uploadrecFile(e) {
                if(e.file) {
                    // qhRecruit.imageUrl = e.url
                    const file = e.file;
                    let formData = new FormData();
                    formData.append("file",file);
                    service.personal.uploadrecFile(formData).then(res => {
                        console.log(res.data.filename);
                        this.iconName = `/userupload/pubrecruit/${ res.data.filename }`;
                        this.$message.success("アイコンがアップロードしました")
                    }).catch(()=>{
                        this.$message.error("アイコンがアップロード出来ませんでした")
                    })
                }
            },
            recruitInfo(data) {
                service.personal.recruitInfo(data).then(res => {
                    this.$emit('isTrue',true)
                    this.caseVal = ""
                    if(res.status === 200) {
                        this.$message.success("募集情報を公開しました")
                    }
                    
                    if(res.status == 201) {
                        this.$message.error("3回以上の募集は出来ません")
                    }
                })
            }
        },
    }
</script>

<style lang="scss" scoped>
    ::v-deep .avatar-uploader .el-upload {
        border: 1px solid #999;
        border-radius: 4px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        width: 60px;
        height: 60px;
        background: #f4f4f4;
    }
    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
    .avatar-uploader-icon {
        font-size: 20px;
        color: #8c939d;
        line-height: 60px;
    }
    .post-box {
        ul {
            // display: flex;
            flex-wrap: wrap;
        li {
            margin-right: 55px;
            /deep/.el-checkbox {
                width: 240px;

            }
            /deep/.el-input-number--small {
                width: 160px;
            }
        }
    }
}
    span.begin {
        display: inline-block;
        width: 160px;
        height: 40px;
        line-height: 40px;
        background-color: #F03747;
        color: #fff;
        text-align: center;
        cursor: pointer;
        border-radius: 5px;
    }
    span.end {
        display: inline-block;
        width: 160px;
        height: 40px;
        line-height: 40px;
        border: 1px solid #F03747;
        color: #F03747;
        text-align: center;
        border-radius: 5px;
        margin-left: 40px;
        cursor: pointer;
    }
    .imgurl {
        display: flex;
        justify-content: center;
        align-items: center;

    }
</style>