<template>
  <div>
    <div>
      <i style="cursor:pointer" @click="goBack" class="iconfont icon-return"></i>
    </div>
    <div class="main">
      <div class="title">
        <div>
          <span>{{ projectName }}</span>
        </div>
        <div>
          <span v-if="isRole&&isshow" @click="inviteEdit">編集者招待</span>
          <span class="circle" @click="getMsgbList">伝言板 <i v-if="projectNewMessage == 1"></i></span>
        </div>
      </div>
      <div class="titleForm">
        <div class="titleImg">
          <img :src="'/userupload/' + projectInfo.icon" alt="">
        </div>
        <div class="formbox">
          <el-form label-width = "400">
            <el-form-item label="漫画タイプ:">
              <span style="margin-left: 0;">{{ projectInfo.type == 1 ? "連載" : "読切" }}</span>
            </el-form-item>
            <el-form-item label="タイトル:">
              <span>{{ projectInfo.title }}</span>
            </el-form-item>
            <el-form-item label="ジャンル:">
                <ul style="display: inline-block;" v-for="(item,index) in typeb" :key="index">
                  <li style="margin-left: 15px;">{{item.typeb}}</li>
                </ul>
            </el-form-item>
            <el-form-item label="創作/エッセイ:">
              <span>{{ projectInfo.creativecap == 1? "創作" : "エッセイ" }}</span>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="outline">
        <p>概要</p>
        <div v-html="outline">
          <!-- {{ outline }} -->
        </div>
      </div>
      <div class="titleText">
        <el-form>
            <el-form-item label="企画書:">
              <span class="textlink" @click="historyDownLoad(projectInfo.proposals)">{{ projectInfo.bookName }}</span>
            </el-form-item>
            <el-form-item label="制作種類:">
              <span>{{ projectInfo.kind == 1? "商業制作":"自主制作"}}</span>
            </el-form-item>
          </el-form>
      </div>
      <div class="files-box">
        <div class="tab">
          <div class="menus-box">
            <ul>
              <li v-for="(item, index) in tabs" :key="index" :class="{ active: index == active }">
                <template v-if="index === 0 && isQhrole">
                  <span @click="addClass(0)">企画</span>
                </template>
                <template v-if="index === 1">
                  <span @click="addClass(1)">案件</span>
                </template>
                <template v-if="index === 2 && isRole">
                  <span @click="addClass(2)">募集</span>
                </template>
              </li>
            </ul>
          </div>
          <div class="btn-box">
            <span v-if="active == 0 && isRole" @click="creatFolder">新しいフォルダ作成</span>
            <span v-if="active == 1 && isRole" @click="creatCase">新しい案件作成</span>
            <!-- <span v-if="active == 2 && isRole" @click="setReward">设置报酬</span> -->
          </div>
        </div>
        <div class="content">
          <div v-if="active == 0 && isQhrole" class="folder">
            <div>
              <div v-for="item in files" :key="item.id" @click="openFloadDrawer(item)">
                <span>
                  <i class="iconfont icon-bg-folder"></i>
                </span>
                <span> {{ item.dirname }} </span>
              </div>
            </div>
          </div>
          <div v-if="active == 1" class="case">
            <div>
              <div v-for="(item, index) in caseArr" :key="index" @click="goCaseDetails(item)">
                <div class="img-box">
                  <img :src="item.icon" alt="" />
                </div>
                <div class="title">
                  <span>{{ item.name }}</span>
                  <span v-if="item.status == 1">ネーム</span>
                  <span v-if="item.status == 2">進行中</span>
                  <span v-if="item.status == 3">校正完了</span>
                </div>
                <p>
                  <span> </span>
                  <!-- <span
                    >{{ item.donepage == null ? 0 : item.donepage }} /
                    {{ item.page }}</span> -->
                </p>
                <div class="update">
                  <span>最終更新時間</span>
                  <span>{{ item.update_at }}</span>
                </div>
              </div>
            </div>
          </div>
          <div v-if="active == 2 && isRole" class="recruit">
            <div>
              <div class="recruit-type">
                <span @click="status = 1" :class="{ active: status == 1 }">企画メンバ募集</span>
                <span @click="status = 2" :class="{ active: status == 2 }">案件メンバー募集</span>
                <span @click="myzhaopinInfo" :class="{ active: status == 3 }">募集情報</span>
              </div>
              <div class="recruit-content">
                <div v-if="status == 1">
                  <recruit @isTrue="isTrue" :qhRecruit="qhRecruit" :kind="1"></recruit>
                  <div class="table" style="margin-top: 20px">
                    <el-table :data="qhDetalis" :header-cell-style="headClass" border style="width: 100%">
                      <el-table-column prop="position" label="募集職業" align="center">
                      </el-table-column>
                      <el-table-column prop="num" label="募集人数" align="center">
                      </el-table-column>
                      <el-table-column prop="applycount" label="応募人数" align="center">
                      </el-table-column>
                      <el-table-column prop="donenum" label="採用人数" align="center">
                      </el-table-column>
                    </el-table>
                  </div>
                  <div class="table" style="margin-top: 20px">
                    <el-table :data="ajtableData" :header-cell-style="headClass" border style="width: 100%">
                      <el-table-column prop="nickname" label="応募者" align="center" width="180">
                      </el-table-column>
                      <el-table-column prop="positionname" label="応募職業" align="center" width="">
                      </el-table-column>
                      <el-table-column prop="id" label="操作" align="center" width="">
                        <template scope="scope">
                          <template v-if="scope.row.isemploy == 0">
                            <el-button @click="agreeJoin(scope.row)" type="text" icon="el-icon-check"
                              size="small">受入</el-button>
                            <el-button @click="refuseJoin(scope.row)" type="text" icon="el-icon-close"
                              size="small">拒否</el-button>
                          </template>
                          <template v-else-if="scope.row.isemploy == 1">
                            <span>受入済</span>
                          </template>
                          <template v-else-if="scope.row.isemploy == 2">
                            <span>拒否済</span>
                          </template>
                        </template>
                      </el-table-column>
                      <el-table-column prop="id" label="メッセージ" align="center" width="">
                        <template scope="scope">
                          <el-button @click="sendMessage(scope.row)" type="text" icon="el-icon-message" size="small">メッセージ
                          </el-button>
                        </template>
                      </el-table-column>
                    </el-table>
                    <div class="page-box" style="margin-top:10px;text-align:right" v-if="count_ > 0">
                      <el-pagination @current-change="handleCurrentChange_" :current-page="currentPage" :page-size="5"
                        layout="total,  prev, pager, next, jumper" :total="count_">
                      </el-pagination>
                    </div>
                  </div>
                </div>
                <div v-if="status == 2">
                  <recruit @isTrue="isTrue" :qhRecruit="ajRecruit" :kind="2"></recruit>
                  <div class="table" style="margin-top: 20px">
                    <el-table :data="ajDetails" :header-cell-style="headClass" border style="width: 100%">
                      <el-table-column prop="position" label="募集職業" align="center">
                      </el-table-column>
                      <el-table-column prop="num" label="募集人数" align="center">
                      </el-table-column>
                      <el-table-column prop="applycount" label="応募人数" align="center">
                      </el-table-column>
                      <el-table-column prop="donenum" label="採用人数" align="center">
                      </el-table-column>
                    </el-table>
                  </div>
                  <div class="table" style="margin-top: 20px">
                    <el-table :data="qhtableData" :header-cell-style="headClass" border style="width: 100%">
                      <el-table-column prop="nickname" label="応募者" align="center">
                      </el-table-column>
                      <el-table-column prop="positionname" label="応募職業" align="center" width="">
                        <!-- chaptername -->
                      </el-table-column>
                      <el-table-column prop="chaptername" label="案件名" align="center" width="">
                      </el-table-column>
                      <el-table-column label="プロフィール" align="center">
                        <template scope="scope">
                          <span @click="openDrew_(scope.row)" style="color:#1875d2;cursor:pointer">プロフィール</span>
                        </template>
                      </el-table-column>
                      <el-table-column prop="id" label="操作" align="center">
                        <template scope="scope">
                          <template v-if="scope.row.isemploy == 0">
                            <el-button @click="agreeJoin(scope.row)" type="text" icon="el-icon-check"
                              size="small">受入</el-button>
                            <el-button @click="refuseJoin(scope.row)" type="text" icon="el-icon-close"
                              size="small">拒否</el-button>
                          </template>
                          <template v-else-if="scope.row.isemploy == 1">
                            <span>受入済</span>
                          </template>
                          <template v-else-if="scope.row.isemploy == 2">
                            <span>拒否済</span>
                          </template>
                        </template>
                      </el-table-column>
                      <el-table-column prop="id" label="メッセージ" align="center" width="auto">
                        <template scope="scope">
                          <el-button @click="sendMessage(scope.row)" type="text" icon="el-icon-message" size="small">メッセージ
                          </el-button>
                        </template>
                      </el-table-column>
                    </el-table>
                    <div class="page-box" style="margin-top:10px;text-align:right" v-if="count > 0">
                      <el-pagination @current-change="handleCurrentChange" :current-page="currentPage" :page-size="5"
                        layout="total,  prev, pager, next, jumper" :total="count">
                      </el-pagination>
                    </div>
                  </div>
                </div>
                <div v-if="status == 3">
                  <el-tabs tab-position="left">
                    <el-tab-pane label="企画">
                      <div style="border:1px solid #ddd;padding:20px;margin-bottom: 20px"
                        v-for="(item, index) in qihuazhaopinApplay" :key="index">
                        <div class="top-content" style="display:flex;">
                          <span style="flex:1">第{{ item.times }}回募集</span>
                          <span v-show="item.isongoing == 1" @click="endzhaopin('1', item)">募集完了</span>
                        </div>
                        <el-table :data="item.time" :header-cell-style="headClass" border
                          style="width: 100%;margin:20px 0">
                          <el-table-column prop="stime" label="募集開始時間" align="center"></el-table-column>
                          <el-table-column prop="etime" label="募集終了時間" align="center"></el-table-column>
                          <el-table-column prop="condition" label="募集要項" align="center"></el-table-column>
                          <el-table-column prop="isongoing" label="ステータス" align="center">
                            <template slot-scope="scope">
                              <span>{{ scope.row.isongoing == '1' ? '募集中' : '募集完了' }}</span>
                            </template>
                          </el-table-column>
                        </el-table>
                        <!-- apply -->
                        <el-table :data="item.applydetail" :header-cell-style="headClass" border style="width: 100%">
                          <el-table-column prop="position" label="募集職業" align="center">
                          </el-table-column>
                          <el-table-column prop="num" label="募集人数" align="center">
                          </el-table-column>
                          <el-table-column prop="applycount" label="応募人数" align="center">
                          </el-table-column>
                          <el-table-column prop="donenum" label="採用人数" align="center">
                          </el-table-column>
                        </el-table>

                        <el-table :data="item.apply" :header-cell-style="headClass" border
                          style="width: 100%;margin:20px 0">
                          <el-table-column prop="nickname" label="応募者" align="center" width="180">
                          </el-table-column>
                          <el-table-column prop="positionname" label="応募職業" align="center" width="">
                          </el-table-column>
                          <el-table-column prop="id" label="操作" align="center" width="">
                            <template scope="scope">
                              <template v-if="scope.row.isemploy == 0">
                                <el-button @click="agreeJoin(scope.row)" type="text" icon="el-icon-check"
                                  size="small">受入</el-button>
                                <el-button @click="refuseJoin(scope.row)" type="text" icon="el-icon-close"
                                  size="small">拒否</el-button>
                              </template>
                              <template v-else-if="scope.row.isemploy == 1">
                                <span>受入済</span>
                              </template>
                              <template v-else-if="scope.row.isemploy == 2">
                                <span>拒否済</span>
                              </template>
                            </template>
                          </el-table-column>
                          <el-table-column prop="id" label="メッセージ" align="center" width="">
                            <template scope="scope">
                              <el-button @click="sendMessage(scope.row)" type="text" icon="el-icon-message"
                                size="small">メッセージ
                              </el-button>
                            </template>
                          </el-table-column>
                        </el-table>
                      </div>
                    </el-tab-pane>
                    <el-tab-pane label="案件">
                      <el-select v-model="anjianId" placeholder="選択下さい" @change="changeanjian">
                        <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                      </el-select>
                      <div style="border:1px solid #ddd;padding:20px;margin-bottom: 20px"
                        v-for="(item, index) in anjianzhaopinApply" :key="index">
                        <div class="top-content" style="display:flex;">
                          <span style="flex:1">第{{ item.times }}回募集</span>
                          <span v-show="item.isongoing == 1" @click="endzhaopin('2', item)">募集完了</span>
                        </div>
                        <el-table :data="item.time" :header-cell-style="headClass" border
                          style="width: 100%;margin:20px 0">
                          <el-table-column prop="stime" label="募集開始時間" align="center"></el-table-column>
                          <el-table-column prop="etime" label="募集終了時間" align="center"></el-table-column>
                          <el-table-column prop="condition" label="募集要項" align="center"></el-table-column>
                          <el-table-column prop="isongoing" label="ステータス" align="center">
                            <template slot-scope="scope">
                              <span>{{ scope.row.isongoing == '1' ? '募集中' : '募集完了' }}</span>
                            </template>
                          </el-table-column>
                        </el-table>
                        <!-- apply -->
                        <el-table :data="item.applydetail" :header-cell-style="headClass" border style="width: 100%">
                          <el-table-column prop="position" label="募集職業" align="center">
                          </el-table-column>
                          <el-table-column prop="num" label="募集人数" align="center">
                          </el-table-column>
                          <el-table-column prop="applycount" label="応募人数" align="center">
                          </el-table-column>
                          <el-table-column prop="donenum" label="採用人数" align="center">
                          </el-table-column>
                        </el-table>

                        <el-table :data="item.apply" :header-cell-style="headClass" border
                          style="width: 100%;margin:20px 0">
                          <el-table-column prop="nickname" label="応募者" align="center" width="180">
                          </el-table-column>
                          <el-table-column prop="positionname" label="応募職業" align="center" width="">
                          </el-table-column>
                          <el-table-column prop="id" label="操作" align="center" width="">
                            <template scope="scope">
                              <template v-if="scope.row.isemploy == 0">
                                <el-button @click="agreeJoin(scope.row)" type="text" icon="el-icon-check"
                                  size="small">受入</el-button>
                                <el-button @click="refuseJoin(scope.row)" type="text" icon="el-icon-close"
                                  size="small">拒否</el-button>
                              </template>
                              <template v-else-if="scope.row.isemploy == 1">
                                <span>受入済</span>
                              </template>
                              <template v-else-if="scope.row.isemploy == 2">
                                <span>拒否済</span>
                              </template>
                            </template>
                          </el-table-column>
                          <el-table-column prop="id" label="メッセージ" align="center" width="">
                            <template scope="scope">
                              <el-button @click="sendMessage(scope.row)" type="text" icon="el-icon-message"
                                size="small">メッセージ
                              </el-button>
                            </template>
                          </el-table-column>
                        </el-table>
                      </div>
                      <div v-if="!anjianzhaopinApply.length" style="line-height:100px">
                        データなし
                      </div>
                    </el-tab-pane>
                  </el-tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 新建文件夹 -->
    <el-dialog title="新しいフォルダ作成" :visible.sync="dialogFormVisible" width="30%">
      <el-form :rules="rules" :model="form" label-position="left" label-width="100px">
        <el-form-item label="フォルダ名" prop="name">
          <el-input v-model="form.name" autocomplete="off" placeholder="フォルダ名を入力して下さい"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取　消</el-button>
        <el-button type="primary" @click="creatFload_">確　定</el-button>
      </div>
    </el-dialog>
    <el-drawer :title="floadName" :visible.sync="drawer" direction="rtl" size="55%">
      <!-- accept=".pdf, .txt, .doc, .docx, .jpg, .png, .xlsx" -->
      <div class="drawer-main my-scrollbar">
        <div class="upLoad">
          <el-upload v-loading="loading" element-loading-text="ファイルアップロード中" element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.6)" class="upload-demo" drag action="no"
            :http-request="upLoadFile" :on-progress="onUploadProgress" :show-file-list="false" multiple>
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              ここにファイルをドラッグして下さい，また<em>クリックしてアップロードして下さい</em>
            </div>
            <div class="el-upload__tip" slot="tip">
              容量は20MB以下でお願い致します
            </div>
          </el-upload>
        </div>

        <div class="fileBox">
          <div class="imageBox">
            <ul>
              <li v-for="(item, index) in imageData" :key="index" @click="openDialog(item, 'image')">
                <div>
                  <div class="img-box">
                    <img :src="item.url" alt="" fit="contain" />
                    <!-- <el-image :src="tem.url" fit="contain"></el-image> -->
                  </div>
                  <span>{{ item.filename }}</span>
                </div>
              </li>
            </ul>
          </div>
          <div class="file_box">
            <div class="file" v-for="(item, index) in fileData" :key="index" @click="fileClick(item, 'file')">
              <div>
                <i class="iconfont icon-shuyi_txt" v-if="item.fileext == 'txt'"></i>
                <i class="iconfont icon-PDF" v-else-if="item.fileext == 'pdf'"></i>
                <i class="iconfont icon-word" v-else-if="item.fileext == 'doc' || item.fileext == 'docx'"></i>
                <i class="iconfont icon-wenjian" v-else-if="item.fileext == 'clip'"></i>
                <i class="iconfont icon-483-file-excel" v-else-if="item.fileext == 'xls'"></i>
                <i class="iconfont icon-wenjian_weizhi-copy" v-else></i>
                <span>{{ item.filename }}</span>
              </div>
              <!-- <el-popover
                v-for="(item, index) in fileData"
                :key="index"
                placement="bottom-end"
                width="260"
                @click="triggerClick"
                :ref="refNamePopover + index"
              >
                <div class="tips-content">
                  <div>
                    <div class="iconBox">
                      <i
                        class="iconfont icon-shuyi_txt"
                        style="color: #ccc; padding:0;"
                        v-if="item.fileext == 'txt'"
                      ></i>
                      <i
                        class="iconfont icon-PDF"
                        style="color:red"
                        v-else-if="item.fileext == 'pdf'"
                      ></i>
                      <i
                        class="iconfont icon-wenjian"
                        style="color:#1875d2"
                        v-else-if="item.fileext == 'clip'"
                      ></i>
                      <i
                        class="iconfont icon-483-file-excel"
                        style="color:#187562"
                        v-else-if="item.fileext == 'xls'"
                      ></i>
                      <i
                        class="iconfont icon-word"
                        style="color: #1875d0"
                        v-else-if="item.fileext == 'doc' || item.fileext == 'docx'"
                      ></i>
                      <i class="iconfont icon-wenjian_weizhi-copy" v-else></i>
                    </div>
                    <div class="content" style="margin-left: 12px">
                      <p>
                        <span>{{ item.filename }}</span>
                      </p>
                      <p>
                        <span> {{ item.filesize }}KB </span>
                        <span>{{ item.create_at }}</span>
                      </p>
                    </div>
                  </div>
                  <div style="text-align: right; margin: 0">
                    <el-button
                      size="mini"
                      type="text"
                      @click="closePopover(index)"
                      >取消</el-button
                    >
                    <el-button
                      type="danger"
                      size="mini"
                      @click="deleteImg(item, index)"
                      >削除</el-button
                    >
                    <el-button
                      type="primary"
                      size="mini"
                      @click="downLoadFile(item, index)"
                      >ダウンロード</el-button
                    >
                  </div>
                </div>
                <div slot="reference">
                  <i
                    class="iconfont icon-shuyi_txt"
                    v-if="item.fileext == 'txt'"
                  ></i>
                  <i class="iconfont icon-PDF" v-else-if="item.fileext == 'pdf'"></i>
                  <i
                    class="iconfont icon-word"
                    v-else-if="item.fileext == 'doc' || item.fileext == 'docx'"
                  ></i>
                  <i
                    class="iconfont icon-wenjian"
                    v-else-if="item.fileext == 'clip'"
                  ></i>
                  <i
                        class="iconfont icon-483-file-excel"
                        v-else-if="item.fileext == 'xls'"
                      ></i>
                  <i class="iconfont icon-wenjian_weizhi-copy" v-else></i> 
                  <span>{{   item.filename}}</span>
                </div>
              </el-popover> -->
            </div>
          </div>
        </div>
      </div>
    </el-drawer>
    <el-drawer title="伝言板" :visible.sync="drawer_" size="40%" direction="rtl">
      <div style="padding:0 20px">
        <el-tabs v-model="activeName">
          <el-tab-pane label="伝言板" name="first">
            <div class="msgboard">
              <div class="msgboardBox my-scrollbar">
                <ul>
                  <li v-for="(item, index) in liuyanData" :key="index">
                    <div class="icon">
                      <el-avatar :size="50" :src="item.pic ? `/userupload/${item.pic}` : circleUrl"></el-avatar>
                    </div>
                    <div class="content_box">
                      <p class="user_">
                        <span>{{ item.from_nickname }}</span>
                        <span>{{ item.create_time }}</span>
                      </p>
                      <p class="content">{{ item.content }}</p>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="input_">
                <el-input style="width:100%" type="textarea" placeholder="チャット内容を入力して下さい" v-model="liuyan" maxlength="60"
                  show-word-limit :autosize="{ minRows: 6, maxRows: 10 }">
                </el-input>
                <span @click="sendMsg">送信</span>
              </div>

            </div>
          </el-tab-pane>
          <el-tab-pane label="参加メンバー一覧" name="second">
            <div style="text-align:right;padding: 10px 0">
              <span>原作者：</span>
              <span>{{ nickname }}</span>
            </div>
            <el-table :data="projectUserData" border style="width: 100%">
              <el-table-column prop="nickname" label="ニックネーム">
              </el-table-column>
              <el-table-column prop="positionname" label="属性">
                <template scope="scope">
                  <span v-if="scope.row.positionname">{{ scope.row.positionname }}</span>
                  <span v-else>編集者</span>
                </template>
              </el-table-column>
              <el-table-column prop="isaddfriend" label="友達へ追加">
                <template scope="scope">
                  <span v-if="!scope.row.isaddfriend">
                    <!-- 既に友達である -->
                  </span>
                  <span @click="addFrined(scope.row)" style="color:#1875d2;cursor:pointer" v-else>
                    友達へ追加
                  </span>
                </template>
              </el-table-column>
              <!-- <el-table-column
            prop="id"
            label="送信"
           >
           <template scope="scope">
             <span style="color:#1875d2;cursor:pointer" @click="sendMessage(scope.row)">送信</span>
           </template>
            
          </el-table-column> -->
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </div>


    </el-drawer>
    <!-- 点击文件夹内图片 -->
    <el-dialog :title="imgItem.filename" :visible.sync="imgDialogVisible" width="45%">
      <div class="imgDetails">
        <div class="left" v-if="fileType == 'image'">
          <img :src="imgItem.url" alt="" fit="contain" />
          <div class="btn-box">
            <span @click="downLoadImage()">
              <i class="iconfont icon-xiazai"></i>
              <span>ダウンロード</span>
            </span>
            <span @click="deleteImg()">
              <i class="iconfont icon-shanchu"></i>
              <span>削除</span>
            </span>
          </div>
        </div>

        <div class="left" v-if="fileType == 'file'">
          <div class="file-icon">
            <!-- <i class="iconfont icon-shuyi_txt"></i> -->
            <i class="iconfont icon-shuyi_txt" v-if="imgItem.fileext == 'txt'"></i>
            <i class="iconfont icon-PDF" v-else-if="imgItem.fileext == 'pdf'"></i>
            <i class="iconfont icon-word" v-else-if="imgItem.fileext == 'doc' || imgItem.fileext == 'docx'"></i>
            <i class="iconfont icon-wenjian" v-else-if="imgItem.fileext == 'clip'"></i>
            <i class="iconfont icon-483-file-excel" v-else-if="imgItem.fileext == 'xls'"></i>
            <i class="iconfont icon-wenjian_weizhi-copy" v-else></i>
          </div>
          <div class="btn-box">
            <span @click="downLoadImage()">
              <i class="iconfont icon-xiazai"></i>
              <span>ダウンロード</span>
            </span>
            <span @click="deleteImg()">
              <i class="iconfont icon-shanchu"></i>
              <span>削除</span>
            </span>
          </div>
        </div>
        <div class="right">
          <p>
            <span>担当者：</span>
            <span>{{ imgItem.nickname }}</span>

          </p>
          <p>
            <span>アップロード時間：</span>
            <span>{{ imgItem.create_at }}</span>
          </p>
          <div class="notes">
            <ul>
              <template v-if="noteData.length">
                <li v-for="(item, index) in noteData" :key="index">
                  <span>{{ item.nickname }}：</span>
                  <span>{{ item.content }}</span>
                </li>
              </template>
              <template v-else>
                <li>
                  <span style="color: #666">コメントなし</span>
                </li>
              </template>
            </ul>
          </div>
          <div class="noteIpt">
            <el-input type="textarea" placeholder="コメントを入力して下さい" v-model="note" maxlength="200" show-word-limit
              :autosize="{ minRows: 3, maxRows: 4 }">
            </el-input>
            <span class="update" @click="updateNote()">更新</span>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">

      </span>
    </el-dialog>
    <el-dialog title="編集者招待" :visible.sync="editVisible" width="45%">
      <div>
        <!-- <el-radio
          @change="eidtChange(item)"
          v-for="(item, index) in userData"
          :key="index"
          v-model="userid"
          :label="item.userid"
          >{{ item.nickname }}</el-radio> -->
        <el-select size="medium" v-model="userid" @change="eidtChange" placeholder="選択して下さい">
          <el-option v-for="item in userData" :key="item.friendid" :label="item.nickname" :value="item.friendid">
          </el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editVisible = false">取　消</el-button>
        <el-button type="primary" @click="invitationEdit">確　定</el-button>
      </span>
    </el-dialog>
    <send-message :sendStatus="sendStatus" @childStatus="childStatus"
      @sendMessageContent="sendMessageContent"></send-message>



    <el-drawer :visible.sync="isDrawer" size="50%" direction="rtl">
      <div class="registerInfo">
        <div class="icon" v-if="users.icon">
          <img :src="'/userupload/' + users.icon" alt="">
        </div>
        <el-descriptions :label-style="LS" title="ユーザー情報" :column="1" style="margin-top:20px" border>
          <el-descriptions-item label="アイコン" v-if="users.pic"> <el-avatar :size="80"
              :src="'/userupload/' + users.pic"></el-avatar> </el-descriptions-item>
          <el-descriptions-item label="ニックネーム">{{ users.nickname }} </el-descriptions-item>
          <el-descriptions-item label="登録属性">{{ users.loginattr == 1 ? 'クリエイター ' : '編集者' }} </el-descriptions-item>
          <el-descriptions-item v-if="users.loginattr == 1" label="参加したい工程" style="width:100px">
            <span style="padding: 0 5px" v-for="(item, index) in positions">{{ item.position }}</span>
          </el-descriptions-item>
          <el-descriptions-item label="媒体">
            <span style="padding: 0 5px" v-for="(item, index) in medias">{{ item.media }}</span>
          </el-descriptions-item>
          <el-descriptions-item label="ジャンル">
            <span style="padding: 0 5px" v-for="(item, index) in typebigs">{{ item.typeb }}</span>
          </el-descriptions-item>
          <el-descriptions-item label="創作/エッセイ">
            <span v-if="users.creativecap == 1" style="margin-left:15px">創作</span>
            <span v-else-if="users.creativecap == 2" style="margin-left:15px">エッセイ</span>
            <span v-else>創作 エッセイ </span>
          </el-descriptions-item>
          <el-descriptions-item v-if="users.theme && users.theme != '#'" label="興味のある題材">
            <p v-for="(item, index) in users.theme.split('#')" :key="index">{{ item }}</p>
          </el-descriptions-item>
          <el-descriptions-item v-if="users.introduction" label="自己PR">
            <p>{{ users.introduction }}</p>
          </el-descriptions-item>
          <el-descriptions-item v-if="users.cartoon" label="好きな漫画">
            <p>{{ users.cartoon }}</p>
          </el-descriptions-item>
          <el-descriptions-item v-if="users.cartooner" label="好きな作家">
            <p>{{ users.cartooner }}</p>
          </el-descriptions-item>
          <el-descriptions-item v-if="users.cute" label="萌え">
            <p>{{ users.cute }}</p>
          </el-descriptions-item>
          <template v-if="users.loginattr == '1'">
            <el-descriptions-item label-class-name="my-label" v-if="users.drawmethod" label="作画方法">
              <p>{{ users.drawmethod == 1 ? "アナログ" : "デジタル" }}</p>
            </el-descriptions-item>
          </template>
          <template v-else>
            <el-descriptions-item label-class-name="my-label" v-if="users.drawmethod" label="所属編集部">
              <p>{{ users.drawmethod == 1 ? "出版社編集部所属" : "フリーランス" }}</p>
            </el-descriptions-item>
          </template>
          <el-descriptions-item v-if="users.reward != '#' && users.loginattr === '1'" label="受賞歴">
            <p>{{ users.reward }}</p>
          </el-descriptions-item>
          <el-descriptions-item v-if="users.experience && users.loginattr == '1'" label="漫画経験">
            <template v-if="users.experience.length > 1">
              <span style="margin-left:15px">アシスタント</span>
              <span style="margin-left:15px">雑誌掲載</span>
            </template>
            <template v-else-if="users.experience == 1">
              <span v-if="users.experience[0] == 1">アシスタント</span>
              <span style="margin-left:15px" v-else>雑誌掲載</span>
            </template>
          </el-descriptions-item>
          <el-descriptions-item v-if="achievements.length" label="製作実績">
            <div style="margin-bottom: 10px" class="achive_" v-for="(item, index) in achievements">
              <span v-if="item.selfProduVal" style="padding: 0 10px 0 0">{{ item.selfProduVal == '1' ? '商業' :
                '自主制作' }}</span>
              <span v-if="item.colorVal" style="padding: 0 10px 0 0">{{ item.colorVal == '1' ? 'カラー' : '白黒' }} </span>
              <span v-if="item.isSerialVal" style="padding: 0 10px 0 0">{{ item.isSerialVal == '1' ? '読切' : '連載' }}</span>
              <span v-if="item.serialSizeVal" style="padding: 0 10px 0 0">{{ item.serialSizeVal == '1' ? '縦スク' : '見開き'
              }}</span>
              <p style="padding: 0 10px 0 0" v-if="item.workName">{{ item.workName }}</p>
              <p style="padding: 0 10px 0 0" v-if="item.author">{{ item.author }}</p>
              <p style="padding: 0 10px 0 0" v-if="item.desc">{{ item.desc }}</p>
            </div>
          </el-descriptions-item>
          <el-descriptions-item v-if="users.introduce" label="ポートフォリオ">
            <p>{{ users.introduce }}</p>
          </el-descriptions-item>
          <el-descriptions-item v-if="users.adscartoon" label="広告マンガ製作依頼">{{ users.adscartoon == 1 ? '受ける ' :
            '受けない' }}</el-descriptions-item>
          <el-descriptions-item v-if="users.drawmethod && users.loginAttr == 2" label="所属編集部">{{ users.drawmethod == 1 ?
            '出版社編集部所属 ' : 'フリーランス' }}</el-descriptions-item>
          <el-descriptions-item v-if="users.publish" label="海外への出版">{{ users.publish == 1 ? '希望する ' :
            '希望しない' }}</el-descriptions-item>
        </el-descriptions>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import service from "@/service";
import recruit from "./recruit/index.vue";
import utils from "@/assets/js/utils";
import sendMessage from "../../../commpoents/sendMessage";
export default {
  components: { recruit, sendMessage },
  data() {
    return {
      LS: {
        'color': '#000',
        'font-weight': '700',
        'text-align': 'right',
        'width': '180px',
        'word-break': 'keep-all'
      },
      fileType: '',
      projectUserData: [],
      liuyan: '',
      liuyanData: [],
      activeName: 'first',
      form: {
        name: "",
      },
      circleUrl: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
      refNamePopover: "popover-",
      dialogFormVisible: false,
      imgDialogVisible: false,
      outline: "",
      projectName: "",
      active: 0,
      tabs: [{ name: "企画" }, { name: "案件" }, { name: "募集" }],
      files: [],
      drawer: false,
      drawer_: false,
      floadName: "",
      floadId: "",
      fileData: [],
      imageData: [],
      isDrawer: false,
      loading: false,
      medias: [],
      typebigs: [],
      achievements: [],
      positions: [],
      users: {},
      rules: {
        name: [
          { required: true, message: "フォルダ名を入力して下さい", trigger: "blur" },
          {
            min: 1,
            max: 15,
            message: "長さは 1 到 15 bit",
            trigger: "blur",
          },
        ],
      },
      projectId: "",
      imgItem: {},
      note: "",
      noteData: [],
      caseArr: [],
      number: 0,
      status: 1,
      qhRecruit: {
        startTime: "",
        endTime: "",
        data: [
          {
            post: "キャラクターデザイナー",
            id: 4,
            checked: false,
            num: 0,
          },
          {
            post: "背景デザイナー",
            id: 5,
            checked: false,
            num: 0,
          },
          {
            post: "メイン作画",
            id: 2,
            checked: false,
            num: 0,
          },
          {
            post: "ネームライター",
            id: 3,
            checked: false,
            num: 0,
          },
          {
            post: "進捗管理マネージャー",
            id: 13,
            checked: false,
            num: 0,
          },
        ],
        outline: "",
        imageUrl: "",
      },
      ajRecruit: {
        startTime: "",
        endTime: "",
        data: [
          {
            post: "背景アシスタント",
            id: 6,
            checked: false,
            num: 0,
          },
          {
            post: "モブアシスタント",
            id: 7,
            checked: false,
            num: 0,
          },
          {
            post: "着彩（カラー）アシスタント",
            id: 8,
            checked: false,
            num: 0,
          },
          {
            post: "ベタ・トーンアシスタント",
            id: 9,
            checked: false,
            num: 0,
          },
          {
            post: "効果アシスタント",
            id: 10,
            checked: false,
            num: 0,
          },
          {
            post: "写植アシスタント",
            id: 11,
            checked: false,
            num: 0,
          },
          {
            post: "その他アシスタント",
            id: 12,
            checked: false,
            num: 0,
          },
          {
            post: "翻訳",
            id: 14,
            checked: false,
            num: 0,
          },
        ],
        outline: "",
        imageUrl: "",

      },
      editVisible: false,
      userData: [],
      userInfo: {},
      userid: "",
      role: [],
      qhtableData: [],
      ajtableData: [],
      sendStatus: false,
      qhDetalis: [],
      ajDetails: [],
      currentPage: 1,
      nickname: '',
      count: 0,
      count_: 0,
      qihuazhaopinApplay: [],
      options: [],
      anjianId: '',
      anjianzhaopinApply: [],
      projectNewMessage: "0",
      projectInfo: {},
      typeb:[],
      isshow:false
    };
  },
  mounted() {
    let id = this.$route.query.pid;
    this.isshow = this.$route.query.showTrue=="true"?true:false
    this.projectId = id;
    this.getProjectInfo(id);
    this.getFload({ projectid: id });
    this.getCaseList({ projectid: id });
    this.role = utils.getStorage("userInfo").data.role.split(",");
    this.getRecruitDetialsList({ operate: "1", projectid: id });
    this.getRecruitDetialsList({ operate: "2", projectid: id });
    this.getApplayList({ operate: 1, projectid: this.projectId, p: 1 });
    this.getApplayList({ operate: 2, projectid: this.projectId, p: 1 });
    console.log(this.isshow);
  },
  watch: {
    isCreatCase: {
      deep: true,
      handler(newVal) {
        if (!newVal) {
          this.getCaseList({ projectid: this.$route.query.pid });
        }
      },
    },
    imgDialogVisible(val) {
      console.log(val)
      if (!val) {
        this.imgItem = {}
        this.noteData = []
      }
    }
  },
  computed: {
    // 图标弹窗
    isRole() {
      if (this.role.indexOf("1") > -1) {
        return true;
      }
      return false;
    },
    isQhrole() {
      if (this.role.indexOf("1") > -1 || this.role.indexOf("3") > -1 || this.role.indexOf("2") > -1 || this.role.indexOf("7") > -1 || this.role.indexOf("4") > -1) {
        return true;
      }
      return false;
    },
  },
  methods: {
    // 下载企划书
    historyDownLoad(data) {
      let fileName = data;
      window.location.href = `/project/downloadrecfile?filename=${fileName}`;
    },
    getTypeData() {
      service.personal.getTypeData().then(res => {
        this.newProject.btype = res.data.data
      })
    },
    openDrew_(row) {
      this.isDrawer = true
      service.personal
        .getUserInfoData({ userid: row.recruituserid })
        .then((res) => {
          if (res.status === 200) {
            this.users = res.data.data;
            this.positions = res.data.positions
            this.medias = res.data.medias
            this.typebigs = res.data.typebigs
            this.achievements = res.data.data.achievements ? res.data.data.achievements.split("#") : []
            this.achievements = this.achievements.map(item => {
              let arr = item.split(';')
              return {
                selfProduVal: arr[0],
                colorVal: arr[1],
                isSerialVal: arr[2],
                serialSizeVal: arr[3],
                workName: arr[4],
                author: arr[5],
                desc: arr[6],
              }
            })
            this.media = res.data.medias;
            this.classification = res.data.typebigs
          }
        })
        .catch((err) => {
          console.log(err);
        });

    },
    handleCurrentChange(val) {
      this.getApplayList({ operate: 2, projectid: this.projectId, p: val });
    },
    handleCurrentChange_(val) {
      this.getApplayList({ operate: 1, projectid: this.projectId, p: val });
    },
    addFrined(data) {

      service.personal
        .addFriend({ fid: data.userid, type: 3 })
        .then((res) => {
          if (res.status === 200) {
            this.$message.success(res.info);
          } else if (res.status === 1) {
            this.$message.success(res.info);
          } else if (res.status === 2) {
            this.$message.success(res.info);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    eidtChange(data) {
      // this.userInfo = data;
    },
    headClass() {
      return "text-align: center;background-color:#eee";
    },
    agreeJoin(row) {
      console.log(row)
      let params = {
        operate: 1,
        applyid: row.id,
        chapterid: row.chapterid,
        projectid: row.projectid,
        userid: row.recruituserid,
        position: row.position,
        recruitdetailid: row.recruitdetailid,
      };
      this.handleApply(params)
      this.myzhaopinInfo()
    },
    handleApply(data) {
      service.personal.handleApply(data).then((res) => {
        if (res.status === 200) {
          this.getApplayList({ operate: 1, projectid: this.projectId });
          this.getApplayList({ operate: 2, projectid: this.projectId });
        } else if (res.status === 201) {
          this.getApplayList({ operate: 2, projectid: this.projectId });
          this.getApplayList({ operate: 1, projectid: this.projectId });
        }
        this.changeanjian()
      });
    },
    refuseJoin(row) {
      let params = {
        operate: 2,
        applyid: row.id,
      };
      this.handleApply(params);
      this.myzhaopinInfo()
    },
    //招聘列表
    getRecruitDetialsList(data) {
      service.personal
        .getRecruitDetialsList(data)
        .then((res) => {
          if (data.operate == 1) {
            this.qhDetalis = res.data;
          } else {
            this.ajDetails = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //结束招聘
    endRecruit(type, id) {
      const params = {
        recruitid: id
      }
      return service.personal.endRecruit(params).then(res => {
        if (res.status === 200) {
          this.$message.success(res.info)
          if (type === "1") {
            this.myzhaopinInfo()
          } else if (type === "2") {
            this.changeanjian()
          }
        }
      })
    },
    endzhaopin(type, item) {
      this.$confirm("本募集を終了しますか？", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.endRecruit(type, item.id)
      }).catch(() => {

      });
    },
    getMsgbList() {
      this.drawer_ = true
      service.personal.getMsgbList({ projectid: this.projectId }).then(res => {
        if (res.status === 200) {
          this.liuyanData = res.data
        }
      });
      service.personal.getProjectUser({ projectid: this.projectId }).then(res => {
        if (res.status === 200) {
          this.projectUserData = res.data
          console.log(res.data, "=-=-=-=")
          this.nickname = res.nickname || ''
        }
      })
    },
    sendMsg() {
      if (this.liuyan) {
        service.personal.msgboardReply(
          { projectid: this.projectId, content: this.liuyan }
        ).then(res => {
          if (res.status === 200) {
            this.liuyan = ''
            this.getMsgbList()
          }
        })
      } else {
        this.$message.warning("送信内容を入力して下さい")
      }

    },
    myzhaopinInfo() {
      this.status = 3;
      const params = {
        projectid: this.projectId
      }
      service.personal.getNewPapplylist(params).then(res => {
        if (res.status === 200) {
          this.qihuazhaopinApplay = res.data
          this.qihuazhaopinApplay = this.qihuazhaopinApplay.map(item => {
            return {
              ...item,
              time: [
                {
                  stime: item.stime,
                  etime: item.etime,
                  condition: item.condition,
                  isongoing: item.isongoing,

                }
              ]
            }
          })
        }
      })

      service.personal.getchpTitle(params).then(res => {
        if (res.status === 200) {
          this.options = res.data
          console.log(this.options, "选项诉讼")
        }
      })
    },
    changeanjian() {
      const params = {
        projectid: this.projectId,
        chapterid: this.anjianId
      }
      service.personal.getNewCapplylist(params).then(res => {
        if (res.status === 200) {
          this.anjianzhaopinApply = res.data
          this.anjianzhaopinApply = this.anjianzhaopinApply.map(item => {
            return {
              ...item,
              time: [
                {
                  stime: item.stime,
                  etime: item.etime,
                  condition: item.condition,
                  isongoing: item.isongoing
                }
              ]
            }
          })
        }
      })
    },
    //发送消息
    sendMessage(data) {
      this.fid = data.recruituserid || data.id;
      this.sendStatus = true;
    },
    getApplayList(data) {
      service.personal.getApplayList(data).then((res) => {
        if (data.operate === 2) {
          this.qhtableData = res.data;
          this.count = res.count;
        } else {
          this.count_ = res.count
          this.ajtableData = res.data;
        }
      });
    },
    isTrue(data) {
      this.qhRecruit.data.forEach(item => {
        if (item.checked) {
          item.checked = false
        }
        item.num = 0;
        this.qhRecruit.startTime = ""
        this.qhRecruit.endTime = ""
        this.qhRecruit.outline = ""
      })
      this.ajRecruit.data.forEach(item => {
        if (item.checked) {
          item.checked = false
        }
        item.num = 0;
      })
      this.ajRecruit.startTime = ""
      this.ajRecruit.endTime = ""
      this.ajRecruit.outline = ""

    },
    invitationEdit() {

      let id = this.$route.query.pid;
      let params = {
        // userid: this.userInfo.friendid,
        userid: this.userid,
        projectid: id,
      };
      service.personal
        .invitationEdit(params)
        .then((res) => {
          if (res.status === 200) {
            this.$message.success("招待メールが送信されました");
            this.editVisible = false;
          } else if (res.status === 2) {
            this.$message.error("既に編集者が参加していますので、招待不可！！");
            this.editVisible = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    childStatus(val) {
      this.sendStatus = val;
    },
    sendMessageContent(text) {
      if (text) {
        let params = {
          fid: this.fid,
          msg: text,
        };
        service.personal
          .sendMessage(params)
          .then((res) => {
            if (res.status === 200) {
              this.$message.success("送信しました");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$message.warning("空送信出来ません！");
      }
    },
    inviteEdit() {
      this.editVisible = true;
      this.getEditList();
    },
    getEditList() {
      service.personal
        .getEditList()
        .then((res) => {
          if (res.status === 200) {
            this.userData = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // file弹窗
    fileClick(data, type) {
      this.imgItem = data;
      this.fileType = type
      this.imgDialogVisible = true;
      this.note = "";
      let params = {
        fileid: data.id,
      };
      this.getFileNote(params);
    },
    // image弹窗
    openDialog(data, type) {
      this.fileType = type
      this.imgDialogVisible = true;
      this.imgItem = data;
      this.note = "";
      let params = {
        fileid: data.id,
      };
      this.getFileNote(params);
    },
    getFileNote(data) {
      service.personal
        .getFileNote(data)
        .then((res) => {
          if (res.status === 200) {
            this.noteData = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateNote() {
      if (this.note) {
        let params = {
          fileid: this.imgItem.id,
          content: this.note,
        };
        service.personal.addFileNote(params).then((res) => {
          if (res.status === 200) {
            this.$message.success("コメント更新しました！");
            this.note = ""
            let params = {
              fileid: this.imgItem.id,
            };
            this.getFileNote(params);
          }
        });
      } else {
        this.$message.error("コメントを入力して下さい");
      }
    },
    closePopover(index) {
      let refName = this.refNamePopover + index;
      this.$refs[refName][0].doClose();
    },
    addClass(index) {
      this.active = index;
    },
    handleClose(done) {
      this.$confirm("閉じますか？").then((a) => {
        console.log(a);
        done();
      });
    },
    handleClose_(done) {
      console.log(done);
    },
    getProjectInfo(data) {
      service.personal.getProjectInfo({ projectid: data }).then((res) => {
        this.projectInfo = res.data
        let index = this.projectInfo.proposals.indexOf('/')
        let name = this.projectInfo.proposals.substr(index+1)
        this.projectInfo.bookName = name
       
      let typebigs = JSON.parse(localStorage.getItem("userInfo")).typebigs
      console.log(typebigs,"ppppp",this.projectInfo.typeb)
        typebigs.forEach(item => {
          let arrtypeb=this.projectInfo.typeb.split(",")
          arrtypeb.forEach((tableId)=>{
            if (tableId == item.id) {
              console.log(item,"=====")
              this.typeb.push(item)
              console.log(this.typeb);
            }
          })
          });
        this.outline = res.data.content;
        this.projectName = res.data.title;
        this.projectNewMessage = res.data.isnew
      });
    },
    creatFload_() {
      let id = this.$route.query.pid;
      if (this.form.name) {
        service.personal
          .creatFload({ dirname: this.form.name, projectid: id })
          .then((res) => {
            if (res.status === 200) {
              this.$message.success(res.info);
              this.getFload({ projectid: id });
            } else {
              this.$message.error(res.info);
            }
          });
        this.dialogFormVisible = false;
      } else {
        this.$message.warning("フォルダ名を入力して下さい");
      }
    },
    creatFolder() {
      this.dialogFormVisible = true;
      this.form.name = "";
    },
    getFload(id) {
      service.personal.getFload(id).then((res) => {
        if (res.status === 200) {
          this.files = res.data;
        }
      });
    },
    //打开文件夹
    openFloadDrawer(item) {
      // let id = this.$route.query.pid;
      this.drawer = true;
      this.floadName = item.dirname;
      this.floadId = item.id;
      this.imageData = [];
      this.fileData = [];
      let params = {
        projectid: this.$route.query.pid,
        dirid: this.floadId,
      };
      this.getProjectFileList(params);
    },

    //获取文件夹数据列表
    getProjectFileList(params) {
      service.personal.getProjectFileList(params).then((res) => {
        this.imageData = [];
        this.fileData = [];
        if (res.status === 200) {
          let data = res.data;
          data.forEach((item) => {
            if (
              item.fileext == "jpg" ||
              item.fileext == "jpeg" ||
              item.fileext == "png"
            ) {
              this.imageData.push(item);
            } else {
              this.fileData.push(item);
            }
          });
        }
      });
    },
    //下载图片
    downLoadImage() {
      let id = this.$route.query.pid;
      window.location.href = `/project/downloadfile?projectid=${id}&dirid=${this.floadId}&filename=${this.imgItem.filename}`;
    },
    //删除图片
    deleteImg(item, index) {
      if (item) {
        let params = {
          fileid: item.id,
        };
        this.deleteFile(params);
      } else {
        let params = {
          fileid: this.imgItem.id,
        };
        this.deleteFile(params);
      }
    },
    goBack() {
      this.$router.go(-1)
    },
    //删除文件
    deleteFile(data) {
      this.$confirm("この操作でファイルを削除しますか?", "ヒント", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        return service.personal.deleteFile(data).then((res) => {
          if (res.status === 200) {
            this.imgDialogVisible = false;
            this.$message({
              type: "success",
              message: "削除しました!",
            });
            let id = this.$route.query.pid;
            let params = {
              projectid: id,
              dirid: this.floadId,
            };
            this.getProjectFileList(params);
          }
        });
      }).catch(() => {
        this.$message({
          type: "info",
          message: "削除を取消しました",
        });
      });
    },
    //上传文件
    upLoadFile(e) {
      const file = e.file;
      let id = this.$route.query.pid;
      let formData = new FormData();
      formData.append("file", file);
      formData.append("projectid", id);
      formData.append("dirid", this.floadId);
      this.loading = true
      service.personal.uploadFileorpic(formData).then((res) => {
        this.loading = false
        if (res.status === 200) {

          this.$message.success("ファイルがアップロードされました");
          let params = {
            projectid: id,
            dirid: this.floadId,
          };
          this.getProjectFileList(params);
        } else {
          this.$message.warning(res.info);
        }
      }).catch(err => {
        this.loading = false
        this.$message.success('アップロード出来ませんでした')
      });
    },
    onUploadProgress: progressEvent => {
      debugger
      let percent = (progressEvent.loaded / progressEvent.total * 100) | 0
      uploader.onProgress({ percent: percent });//调用uploader的进度回调
    },
    //下载文件
    downLoadFile(data, index) {
      window.location.href = `/project/downloadfile?projectid=${data.projectid}&dirid=${data.dirid}&filename=${data.filename}`;
      let refName = this.refNamePopover + index;
      this.$refs[refName][0].doClose();
    },
    //获取案件列表
    getCaseList(data) {
      service.personal.getCaseList(data).then((res) => {
        if (res.status === 200 && res.data.length) {
          this.caseArr = res.data;
          this.$message.success("案件リストを取得しました！");
        }
      });
    },
    //创建案件
    creatCase() {
      this.number = this.caseArr.length;
      let pid = this.$route.query.pid;
      this.$router.push({
        path: "creat-case",
        query: {
          id: 1,
          pid: pid,
          num: this.number,
        },
      });
    },
    //点击案件列表进入案件详情
    goCaseDetails(item) {
      let pid = this.$route.query.pid;
      // 开始采用的字段是status，12.3采用issend
      if (item.issend != 2) {
        this.$router.push({
          path: "proces-Rout",
          query: {
            id: 1,
            pid: pid,
            params: JSON.stringify(item),
          },
        });
      } else {
        this.$router.push({
          path: "cart-details",
          query: {
            id: 1,
            pid: pid,
            params: JSON.stringify(item),
          },
        });
      }
    },
    //设置报酬
    setReward() { },
  },
};
</script>

<style lang="scss" scoped>
// 注册信息
::v-deep.registerInfo {
  overflow-y: auto;

  .icon {
    height: 160px;
    text-align: center;

    img {
      max-height: 100%;
    }
  }

  padding: 20px;

  ul {
    width: 100%;

    li {
      span {
        margin-left: 20px;
        font-size: 16px;
      }

      height: 32px;

      h4 {
        font-size: 18px;
      }

      div {
        padding-left: 20px;
      }
    }
  }
}

.top-content {
  display: flex;
  align-items: center;

  >span:last-child {
    display: inline-block;
    width: 100px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: #fff;
    background: #f03747;
    margin: 0 10px;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 10px;
  }
}

/deep/.el-upload-dragger {
  height: 300px !important;
  width: 800px;

  .el-icon-upload {
    margin: 90px 0 16px !important;
  }
}

.msgboard {
  .msgboardBox {
    max-height: 550px;
    overflow: auto;
    padding-right: 8px;

    ul {
      li {
        display: flex;
        margin-bottom: 10px;

        .icon {
          margin-right: 13px;
        }

        .content_box {
          flex: 1;

          .user_ {
            display: flex;
            justify-content: space-between;

            span:first-child {
              font-size: 16px;
              font-weight: 500;
              color: #000;
            }
          }

          .content {
            color: #666;
            margin-top: 5px;
          }
        }
      }
    }
  }

  .input_ {
    width: 100%;
    margin-top: 20px;
    text-align: right;

    span {
      display: inline-block;
      width: 100px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      color: #fff;
      background: #f03747;
      margin: 0 10px;
      border-radius: 8px;
      cursor: pointer;
      margin-top: 10px;
    }
  }
}

.title {
  display: flex;

  div:first-child {
    flex: 1;
    align-items: center;
    line-height: 50px;

    span {
      color: #333;
      font-size: 16px;
      font-weight: 500;
    }
  }

  div:last-child {
    span {
      display: inline-block;
      width: 160px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      color: #fff;
      background: #f03747;
      margin: 0 10px;
      border-radius: 8px;
      cursor: pointer;
    }
  }
}

.titleForm {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  ::v-deep .el-form-item {
   
    // margin-bottom: 0;
  }
  
  .titleImg{
    position: relative;
    height: 350px;
    width: 350px;
    margin: 0 auto;
    // height: 100%;
    text-align: center;
    img {
    max-width: 100%;
    max-height: 100%;
    position:absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;

    }
  }
  .formbox {
    margin-top: 20px;
  }
}

.outline {
  p {
    color: #333;
    font-size: 16px;
    font-weight: 500;
    margin: 20px 0 20px 0;
  }

  div {
    border: 1px solid #bfbfbf;
    border-radius: 8px;
    height: 100px;
    padding: 15px;
  }
}
.titleText {
  ::v-deep .el-form-item {
    margin-bottom: 0;
  }
  .textlink {
    cursor: pointer;
    color: #1875d2;
  }
}
.files-box {
  margin: 30px 0;

  .tab {
    display: flex;
    justify-content: center;

    .menus-box {
      flex: 1;

      ul {
        display: flex;

        li {
          padding: 0 20px;
          text-align: center;
          cursor: pointer;
          color: #333;
          line-height: 50px;
          font-size: 20px;
        }

        li.active {
          color: #f03747;
        }
      }
    }

    .btn-box {
      span {
        display: block;
        width: 160px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        color: #fff;
        background: #f03747;
        border-radius: 8px;
        cursor: pointer;
      }
    }
  }

  .content {
    padding-bottom: 130px;
    .folder {
      >div {
        display: flex;
        flex-wrap: wrap;

        div {
          padding: 10px;
          background-color: #fff;
          margin: 0 10px;
          cursor: pointer;

          span {
            display: block;
            text-align: center;

            // line-height: 35px;
            i {
              font-size: 80px;
              font-weight: 400;
              color: rgb(244, 226, 134);
            }
          }

          span:last-child {
            margin-top: 5px;
          }
        }
      }
    }

    .case {
      >div {
        display: flex;
        flex-wrap: wrap;

        >div {
          width: 20%;
          min-width: 150px;
          margin: 10px;
          cursor: pointer;
          box-shadow: #ededed 6px 6px 4px;
          padding: 0 10px;

          .img-box {
            position: relative;
            width: 100%;
            height: 200px;
            min-width: 160px;
            min-height: 160px;

            img {
              display: block;
              max-width: 100%;
              max-height: 100%;
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              margin: auto;
            }
          }

          .title {
            display: flex;
            align-items: center;
            font-size: 12px;

            span:first-child {
              flex: 1;
              color: #333;
            }

            span:last-child {
              background-color: #1875d2;
              display: inline-block;
              color: #fff;
              padding: 3px 8px;
              border-radius: 4px;
            }
          }

          p {
            display: flex;
            align-items: center;
            color: #999;
            font-size: 12px;
            border-bottom: 1px solid #ddd;
            line-height: 30px;

            span:first-child {
              flex: 1;
            }
          }

          .update {
            line-height: 30px;
            display: flex;
            align-items: center;
            color: #333;
            font-size: 12px;
            margin-top: 10px;

            span:first-child {
              flex: 1;
            }
          }
        }
      }
    }

    .recruit {
      >div {
        .recruit-type {
          span {
            display: inline-block;
            padding: 6px 20px;
            cursor: pointer;
          }

          span.active {
            background: rgba(240, 55, 71, 0.1);
            // opacity: 0.1;
            border-radius: 4px;
            color: #f03747;
          }
        }

        .recruit-content {
          margin-top: 30px;

          ::v-deep .avatar-uploader .el-upload {
            border: 1px solid #999;
            border-radius: 4px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            width: 60px;
            height: 60px;
            background: #f4f4f4;
          }

          .avatar {
            width: 178px;
            height: 178px;
            display: block;
          }

          .avatar-uploader-icon {
            font-size: 20px;
            color: #8c939d;
            line-height: 60px;
          }

          .post-box {
            ul {
              display: flex;
              flex-wrap: wrap;

              li {
                margin-right: 55px;

                /deep/.el-checkbox {
                  margin-right: 20px;
                }

                /deep/.el-input-number--small {
                  width: 140px;
                }
              }
            }
          }
        }

        span.begin {
          display: inline-block;
          width: 160px;
          height: 40px;
          line-height: 40px;
          background-color: #f03747;
          color: #fff;
          text-align: center;
          cursor: pointer;
          border-radius: 5px;
        }

        span.end {
          display: inline-block;
          width: 160px;
          height: 40px;
          line-height: 40px;
          border: 1px solid #f03747;
          color: #f03747;
          text-align: center;
          border-radius: 5px;
          margin-left: 40px;
          cursor: pointer;
        }
      }
    }
  }
}

.upLoad {
  text-align: center;
  text-align: center;
  position: sticky;
  background: #fff;
  top: 0px;
  z-index: 10;
  padding-bottom: 10px;

  /deep/.el-upload-dragger {
    height: 150px;

    .el-icon-upload {
      margin: 30px 0 16px;
    }
  }
}

.fileBox {
  margin-top: 20px;
  padding: 0 40px;

  .file_box {
    display: flex;
    flex-wrap: wrap;

    >div {
      cursor: pointer;
      border: 1px solid #ddd;
      margin-right: 10px;
      margin-bottom: 10px;
      width: 22%;
      min-width: 150px;
      padding: 20px 0;
      text-align: center;
      margin-top: 20px;

      i {
        font-size: 80px;
        width: 16%;
        min-width: 150px;
      }

      span {
        margin-top: 15px;
        display: block;
      }
    }
  }

  // .file {
  //   margin-top: 20px;
  //   padding-bottom: 20px;
  //   display: flex;
  //   flex-wrap: wrap;

  //     >span {
  //     margin-left: 10px;
  //     width: 16%;
  //     min-width: 150px;
  //     }
  //     >span:first-child {
  //       margin-left: 0;
  //     }
  //   /deep/ .el-popover__reference {
  //     border: 1px solid #ddd;
  //     cursor: pointer;
  //     text-align: center;
  //     // line-height: 30px;
  //     // background-color: #ededed;
  //     // margin-top: 10px;
  //     // padding: 0 10px;
  //     // cursor: pointer;
  //     i {
  //       font-size: 80px;
  //     }
  //     span {
  //       height: 40px;
  //       margin-top: 10px;
  //       display: block;
  //       font-size: 14px;
  //       margin-left: 10px;
  //     }
  //   }
  //   ul {
  //     li {
  //       display: flex;
  //       align-items: center;
  //       background-color: #ededed;
  //       padding: 0 10px;
  //       line-height: 30px;
  //       cursor: pointer;
  //       margin-bottom: 10px;
  //       i {
  //         font-size: 20px;
  //       }
  //       span {
  //         font-size: 14px;
  //         margin-left: 10px;
  //       }
  //     }
  //   }
  // }
  .imageBox {
    margin-top: 20px;

    ul {
      display: flex;
      flex-wrap: wrap;

      li {
        width: 22%;
        min-width: 150px;
        cursor: pointer;
        margin-right: 10px;
        border: 1px solid #ddd;
        margin-bottom: 10px;

        .img-box {
          height: 150px;
          width: 100%;
          min-width: 150px;
          position: relative;

          img {
            display: block;
            max-width: 100%;
            max-height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
          }
        }

        span {
          text-align: center;
          display: block;
          width: 100%;
          margin-top: 5px;
        }
      }
    }
  }

}

.tips-content {
  >div:first-child {
    display: flex;
    align-items: center;
    padding: 10px 0;

    .content {
      p:last-child {
        display: flex;
        margin-top: 6px;

        span {
          display: block;
          color: #666;
        }

        span:first-child {
          margin-right: 30px;
          flex: 1;
        }

        font-size: 12px;
      }
    }
  }

  i {
    font-size: 40px;
  }
}

.imgDetails {
  display: flex;
  min-height: 260px;

  .left {
    width: 55%;

    .btn-box {
      float: left;

      >span {
        display: inline-block;
        line-height: 30px;
        margin-right: 15px;
        color: #666;
        cursor: pointer;
      }
    }

    img {
      width: 100%;
    }

    .file-icon {
      width: 100%;
      height: 250px;
      text-align: center;

      i {
        display: block;
        padding-top: 40px;
        font-size: 180px;
      }
    }
  }

  .right {
    margin-left: 20px;
    width: 42%;

    p {
      line-height: 25px;
    }

    p:first-child {
      span {
        color: #333;
      }
    }

    p:last-child {
      span {
        color: #666;
      }
    }

    .notes {
      margin-top: 20px;

      ul {
        max-height: 230px;
        overflow: auto;

        li {
          color: #333;
          line-height: 30px;
        }
      }
    }

    .noteIpt {
      margin-top: 10px;
      position: relative;

      .update {
        display: block;
        width: 60px;
        height: 30px;
        background: #ffffff;
        border: 1px solid #f03747;
        border-radius: 8px;
        text-align: center;
        line-height: 30px;
        color: #f03747;
        margin-top: 10px;
        cursor: pointer;
        position: absolute;
        right: 0;
      }
    }
  }
}

.drawer-main {
  overflow-y: auto;
  height: 100%;
}

.circle {
  position: relative;

  i {
    position: absolute;
    top: 0;
    right: 0;
    width: 15px;
    height: 15px;
    background-color: rgb(4, 228, 112);
    border-radius: 50%;
  }
}
</style>
